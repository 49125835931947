/*Nav*/
* {
    align-items: center; /* Center vertically */
    /*height: 100vh;*/ /* Full viewport height */
    /* width: 100vw;*/ /* Full viewport width */
    margin: 0; /* Remove default margin */
    padding: 0; /* Remove default padding */
    box-sizing: border-box;
}
@media (min-width: 1400px) {
    .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
        max-width: initial;
    }
}
.kTXctF {
    display: flex;
    flex-direction: column;
    width:100%
}
.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: 200px;
    margin-left: 200px;
}


/* For header section styling */
/*.header {
    position: relative;
    height: 100vh;*/  /*Full viewport height */
/*}

.header-background {
    background-size: cover;
    background-position: center;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.header h1 {
    font-size: 2.5rem;
    text-align: center;
    padding: 20px;
    font-weight: bolder;
}*/
.header {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, Helvetica, sans-serif;
    display: flex;
    height: 100vh;
    background-image:url("../img/building.jpg");
    background-size: cover;
}
.content {
    display: flex;
    border:1px purple solid;
    flex-direction: column;
    min-width: 100%;
    width:100%;
    margin: 0 auto;
    top:100px;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.content h1{
    font-size:50px;
    font-weight:bolder;
}
/*Tabs*/
.nav {
    background-color: #331b8c;
}
.nav-tabs .nav-link {
    background-color: transparent !important;
    color: #FFFFFF !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    min-width: 100%;
}
.nav-tabs .nav-link.active {
    border: solid 4px white !important;
}
/*Forms*/
.required
{
background-color:black;
color:white;
padding:2px;
border-radius:5px;      
}
.aestric{
    color:red;
    font-weight:bold;
    font-size:medium
}
/*DisplaySavedMessage*/
.toast {
    margin: auto;
    justify-content: center;
    align-items: center;
    text-align: center;
}
/*Icons*/
.iconStyles {
    color: cornflowerblue;
}
.tooltipColor {
    background-color: mediumpurple;
}
.fonts{
    font-size:medium;
    font-weight:bold;
}
/*ReturnsList*/
#content {
    width: 100%;
    border-top: 2px solid #331b8c;
    padding: 4px;
    
}
#left {
    float: left;
    width: 20%;
}
#middle{
    float: left;
    width: 5%;
}
#right {
    float: left;
    margin: 0px;
    width: 75%;
}

#content hr {
    border-width: 1px 0 0 0;
    border-color: #331b8c;
    margin: 0px;
    padding-top: 20px;
}


